<template>
    <div class="rc-factoring">
        <v-container class="rc-padding fluid">
            <v-layout row pb-5 wrap>
                <v-flex xs12 mt-5 style="text-align: center;">
                    <h1 class="rc-tituloSection" style="margin-bottom:2rem">Puedes acceder a:</h1>
                </v-flex>
                <v-flex xs12 mt-5 class="factoring__content">
                    <v-btn to="/solicitar-credito" class="rc-button_prim content__button">Factoring</v-btn>
                    <p>
                        Una opción que tienen las <strong>pymes</strong> y los <strong>emprendedores</strong>
                        para adelantar el cobro de sus facturas
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row pb-5 wrap>
                <v-flex class="hidden-sm-and-down" xs12 mt-5 style="text-align: center;">
                    <img src="/images/como-funcionaView/infografia_desk.png" alt="Infografia" style="max-width:880px;width:100%;">
                </v-flex>
                <v-flex class="hidden-md-and-up" xs12 mt-5 style="text-align: center;display:flex;flex-flow:column;align-items: center;">
                    <img src="/images/como-funcionaView/1y2.png" alt="Infografia paso 1 y 2" style="width: 100%;max-width: 400px;margin-bottom:1rem;">
                    <img src="/images/como-funcionaView/3y4.png" alt="Infografia paso 3 y 4" style="width: 100%;max-width: 400px;margin-bottom:1rem;">
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		/* props: {
			titulo : String,
			listado : Array
        }, */
	})
	export default class Factoring extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-factoring{
        min-height: 800px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .factoring__content{
            display: flex;
            flex-flow: column;
            align-items: center;
            text-align: center;
            .content__button{
                border-radius: 12px;
                text-transform: capitalize;
                font-size: 1.3rem !important;
                font-weight: 500;
                width: auto;
                padding-left: 1rem;
                padding-right: 1rem;
                min-width: 200px;
                height: 46px;
                margin-bottom: 3rem;
            }
            p{
                font-size: 1.3rem;
                max-width: 560px;
                strong{
                    color: $rc-prim-n1;
                }
            }
        }
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
                
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
                
            }@else if $breakpoint == "small-to-medium"{
               
            }@else if $breakpoint == "small"{
                
            }
		}
	}
</style>
