
































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  TitleTemplatePublic,
  SelectedCountry,
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import Currency from "@/components/Currency.vue";
import ReusableButton from "@/components/ReusableButton.vue";
import Factoring from "@/components/solicitView/Factoring.vue";
import Simulador from "@/components/solicitView/Simulador.vue";
import { EventBus } from "@/main";

@Component({
  metaInfo: {
    title: "RedCapital  | Plataforma de Financiamiento Digital ",
    //titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user", "token", "business", "amountFinanced"]),
  },
  watch: {
    "simulate.amount"(val) {
      if (val < 300000) {
        this.$data.simulationText = "Mínimo de $300.000";
      } else if (val >= 300000 && val < 1250000) {
        this.$data.simulationText =
          "Si vendes sobre $4.000.000 mensuales, puedes solicitar hasta $1.250.000 sin problemas";
      } else if (val >= 1250000 && val < 1500000) {
        this.$data.simulationText =
          "Si vendes sobre $5.000.000 mensuales, puedes solicitar hasta $1.500.000 sin problemas";
      } else if (val >= 1500000 && val < 2000000) {
        this.$data.simulationText =
          "Si vendes sobre $7.000.000 mensuales, puedes solicitar hasta $2.000.000 sin problemas";
      } else if (val >= 2000000 && val < 2500000) {
        this.$data.simulationText =
          "Si vendes sobre $9.000.000 mensuales, puedes solicitar hasta $2.500.000 sin problemas";
      } else if (val >= 2500000 && val < 20000000) {
        this.$data.simulationText = "El máximo a simular es $20.000.000";
      } else if (val >= 20000000) {
        this.$data.simulationText =
          "Por ahora, solo puedes simular hasta $20.000.000";
      }
    },
    el() {
      if (this.$data.el == 1) {
        this.$data.supportText =
          "Te ayudaré a simular un crédito para impulsar tu empresa";
        this.$data.title = "Simula tu crédito, sin compromisos";
      } else if (this.$data.el == 2) {
        this.$data.supportText =
          "Este es un aproximado, no es necesario que sea exacto, sólo piensa en un promedio mensual";
        this.$data.title = "Simula tu crédito, sin compromisos";
      } else if (this.$data.el == 3) {
        this.$data.supportText =
          "Tienes mínimo 1 mes y máximo 60 meses para terminar de pagar";
        this.$data.title = "Simula tu crédito, sin compromisos";
      } else if (this.$data.el == 4) {
        this.$data.supportText =
          "Si aún no tienes inicio de actividades o esta en proceso, ¡Contáctanos!";
        this.$data.title = "Simula tu crédito, sin compromisos";
      } else if (this.$data.el == 5) {
        this.$data.supportText = "Escribe el nombre de tu empresa";
        this.$data.title = "Simula tu crédito, sin compromisos";
      } else if (this.$data.el == 6) {
        this.$data.supportText =
          "Escribe tu correo electrónico para enviar los detalles de la simulación";
        this.$data.title = "Simula tu crédito, sin compromisos";
      } else if (this.$data.el == 7) {
        this.$data.supportText =
          "Escribe tu correo electrónico para enviar los detalles de la simulación";
        this.$data.title = "¡Acá están tus resultados!";
      }
    },
    "simulate.sale"(v) {
      v == "Entre $2 y $10 Millones"
        ? (this.$data.simulate.month = 5)
        : (this.$data.simulate.month = 12);
    },
  },
  data() {
    return {
      intentos: false,
      SelectedCountry,
      navigationNext: `<i class="material-icons primary--text">keyboard_arrow_right</i>`,
      navigationPrevious: `<i class="material-icons primary--text">keyboard_arrow_left</i>`,
      test: null,
      quote: null,
      el: 1,
      step1: false,
      results: [],
      valid: true,
      errorMessage: null,
      averageSale: [
        { text: "Entre $0 y $2 Millones", id: 1 },
        { text: "Entre $2 y $10 Millones", id: 2 },
        { text: "sobre $10 Millones", id: 3 },
      ],
      averageWho: [
        { text: "Personas", id: 1 },
        { text: "Empresas", id: 2 },
        { text: "Ambas", id: 3 },
      ],
      averageActivities: [
        { text: "Si", id: 1 },
        { text: "No", id: 2 },
      ],
      title: "Simula tu crédito, sin compromisos",
      supportText: "Te ayudaré a simular un crédito para impulsar tu empresa",
      minInvest: 299999,
      maxInvest: 20000001,
      simulationText: null,
      nameRules: [
        (v: any) => !!v || "Campo requerido",
        (v: any) =>
          v.toString().length >= 5 || "Nombre debe tener al menos 5 caracteres",
      ],
      mailRules: [
        (v: any) => !!v || "E-mail es requerido",
        (v: any) => /^.+@.+\..+/gi.test(v) || "El e-mail tiene que ser valido",
      ],
      simulate: {
        amount: 0,
        sale: null,
        who: null,
        activities: null,
        name: "",
        mail: null,
        month: 12,
        quote: null,
        tasa_anual: null,
        tasa_interes: null,
      },
      btn1: {
        clickable: true,
        loading: false,
        isOk: null,
        successText: "Ok",
        errorText: "Error",
      },
      btn2: {
        clickable: true,
        loading: false,
        isOk: null,
        successText: "Ok",
        errorText: "Error",
      },
      btn3: {
        clickable: true,
        loading: false,
        isOk: null,
        successText: "Ok",
        errorText: "Error",
      },
      btn4: {
        clickable: true,
        loading: false,
        isOk: null,
        successText: "Ok",
        errorText: "Error",
      },
      btn5: {
        clickable: true,
        loading: false,
        isOk: null,
        successText: "Ok",
        errorText: "Error",
      },
      btn6: {
        clickable: true,
        loading: false,
        isOk: null,
        successText: "Ok",
        errorText: "Error",
      },
      amounts: {
        financed: 0,
        pymes: 0,
        lastThirtyDays: 0,
        render: {
          financed: 0,
          pymes: 0,
          lastThirtyDays: 0,
        },
      },
      ip: null,
    };
  },
  async mounted() {
    let amounts: any = await API.getAmounts();
    if (amounts.principal != null) {
      let dataHome = {
        amountFinanced : parseInt(amounts.principal),
        amountPymes : parseInt(amounts.pymes)
      };
      this.$store.commit("setAmountDataHome", dataHome);
      this.$data.amounts.financed = parseInt(amounts.principal);
      this.$data.amounts.pymes = parseInt(amounts.pymes);
    } else {
      this.$data.amounts.financed = this.$store.state.amountFinanced;
      this.$data.amounts.pymes = this.$store.state.amountPymes;
    }
    this.$data.amounts.lastThirtyDays = parseInt(amounts.secundario);
    let steps = 20;
    let timePerStep = 1;
    for (
      let i = this.$data.amounts.financed / steps, j = this.$data.amounts.pymes / steps;
      i < this.$data.amounts.financed && j < this.$data.amounts.pymes;
      i += this.$data.amounts.financed / steps, j += this.$data.amounts.pymes / steps
    ) {
      await new Promise((res, rej) => {
        setTimeout(() => {
          // @ts-ignore
          this.$data.amounts.render.financed = parseInt(i);
          // @ts-ignore
          this.$data.amounts.render.pymes = parseInt(j);
          // @ts-ignore
          res();
        }, timePerStep);
      });
    }
    
    this.$data.amounts.render.financed = this.$data.amounts.financed;
    this.$data.amounts.render.pymes = this.$data.amounts.pymes;

    if (this.$route.hash) {
      setTimeout(() => {
        if (this.$route.hash != "#chat") {
          // @ts-ignore
          document.querySelector(this.$route.hash).scrollIntoView();
        }
      }, 1000);
    }
  },
  methods: {
    gosabermas() {
      this.$router.push({
        path: "/actividad/sabermas",
      });
    },
    openLoginDialog() {
      EventBus.$emit("open-login-dialog", this.$route.path);
    },
    restart() {
      // volver a iniciar la simulación
      this.$data.el = 1;
      this.$data.simulate.amount = 0;
      this.$data.simulate.sale = null;
      this.$data.simulate.who = null;
      this.$data.simulate.activities = null;
      this.$data.simulate.name = "";
      this.$data.simulate.mail = null;
      (this.$data.simulate.month = 12),
        (this.$data.simulate.quote = null),
        (this.$data.simulate.tasa_anual = null),
        (this.$data.simulate.tasa_interes = null);
    },
    openAivo() {
      (function () {
        // @ts-ignore
        window.$aivo.chat.open();
      })();
    },
    saveAmount() {
      let idsuser = 0;
      let s = "";
      var us = localStorage.getItem("vuex");
      var f = JSON.parse(JSON.stringify(us));

      if (this.$data.maxsimulation >= 3) {
        this.$data.intentos = true;
      } else {
        this.$data.el++;
        API.registraranalytics(
          "Solicitar",
          this.$data.ip,
          this.$data.simulate.amount,
          idsuser
        )
          .then((res: any) => {
            // console.log('datos registrados');
          })
          .catch((err: any) => {
            console.log(err);
          });
      }

      this.$data.btn1.loading = true;
      setTimeout(() => {
        // console.log(this.$data.simulate.amount, this.$data.simulate.month)
        if (
          this.$data.simulate.amount < this.$data.maxInvest &&
          this.$data.simulate.amount > this.$data.minInvest
        ) {
          this.$data.btn1.isOk = true;
          this.$data.errorMessage = null;
          // this.$data.el++
          // API.getQuote(this.$data.simulate.amount, this.$data.simulate.month).then((res: any) => {
          //   this.$data.quote = res.data

          //   console.log('getQuote',res.data)
          //   this.$data.test = "it works"
          // }).catch((error: any) =>
          // this.$data.test = "error"
          // )
        } else {
          this.$data.btn1.isOk = false;
          this.$data.errorMessage =
            "El monto debe ser mayor a $300.000 y menor a $20.000.000";
        }
      }, 1000);
    },
    saveSale() {
      this.$data.btn2.loading = true;
      setTimeout(() => {
        if (
          this.$data.simulate.amount < this.$data.maxInvest &&
          this.$data.simulate.amount > this.$data.minInvest
        ) {
          this.$data.btn1.isOk = true;
          this.$data.errorMessage = null;
          // this.$data.el++
          API.getQuote(this.$data.simulate.amount, this.$data.simulate.month)
            .then((res: any) => {
              this.$data.quote = res.data;
              this.$data.simulate.tasa_interes = (
                res.data.tasa_interes * 100
              ).toFixed(2);
              setTimeout(() => {
                if (this.$data.simulate.sale != null) {
                  this.$data.btn2.isOk = true;
                  this.$data.errorMessage = null;
                  this.$data.el++;
                  this.$data.simulate.quote = this.$data.quote.Cuotas;
                } else {
                  this.$data.btn2.isOk = false;
                  this.$data.errorMessage = "La venta no puede estar vacía";
                }
              }, 1000);
              this.$data.test = "it works";
            })
            .catch((error: any) => (this.$data.test = "error"));
        } else {
          this.$data.btn1.isOk = false;
          this.$data.errorMessage =
            "El monto debe ser mayor a $300.000 y menor a $20.000.000";
        }
      }, 1000);
    },
    saveWho() {
      this.$data.btn3.loading = true;
      setTimeout(() => {
        if (this.$data.simulate.who != null) {
          this.$data.btn3.isOk = true;
          this.$data.errorMessage = null;
          this.$data.el++;
        } else {
          this.$data.btn3.isOk = false;
          this.$data.errorMessage = "El tiempo no puede estar vacío";
        }
      }, 1000);
    },
    saveActivities() {
      this.$data.btn4.loading = true;
      setTimeout(() => {
        if (this.$data.simulate.activities != null) {
          this.$data.btn4.isOk = true;
          this.$data.errorMessage = null;
          this.$data.el++;
        } else {
          this.$data.btn4.isOk = false;
          this.$data.errorMessage = "Tienes que seleccionar alguna opción";
        }
      }, 1000);
    },
    finish() {
      this.$data.btn4.loading = true;
      setTimeout(() => {
        if (this.$data.simulate.activities != null) {
          this.$data.btn4.isOk = true;
          this.$data.errorMessage = null;
          this.$data.el = 8;
        } else {
          this.$data.btn4.isOk = false;
          this.$data.errorMessage = "Tienes que seleccionar alguna opción";
        }
      }, 1000);
    },
    saveName() {
      this.$data.btn5.loading = true;
      setTimeout(() => {
        if (this.$data.simulate.name != null) {
          this.$data.btn5.isOk = true;
          this.$data.errorMessage = null;
          this.$data.el++;
        } else if (this.$data.simulate.name.length < 5) {
          this.$data.btn5.isOk = false;
          this.$data.errorMessage =
            "El nombre debe tener al menos 5 caracteres";
        } else {
          this.$data.btn5.isOk = false;
          this.$data.errorMessage = "El nombre no puede estar en blanco";
        }
      }, 1000);
    },
    saveMail() {
      this.$data.btn6.loading = true;
      setTimeout(() => {
        if (this.$data.simulate.mail != null) {
          this.$data.btn6.isOk = true;
          this.$data.errorMessage = null;
          this.$data.el++;
          API.actionSimulate(this.$data.simulate)
            .then((res: any) => {
              this.$store.commit("setLastSimulation", res.data);
              this.$data.test = "it works";
            })
            .catch((error: any) => (this.$data.test = "error"));
        } else {
          this.$data.btn6.isOk = false;
          this.$data.errorMessage = "";
        }
      }, 2000);
    }
  },
  components: {
    Currency,
    ReusableButton,
    Factoring,
    Simulador
  },
})
export default class Action extends Vue {}
