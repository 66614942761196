<template>
    <div class="rc-simulador">
        <v-container class="rc-padding fluid">
            <v-layout row wrap>
                <v-flex xs12 style="text-align: center;margin-bottom:5rem">
                    <h1 class="rc-tituloSection">Simulador de financiamiento</h1>
                </v-flex>
            </v-layout>
            <v-layout row pb-5 wrap>
                <v-flex xs12>
                    <v-card class="rc-shadow mx-auto fill-height" style="min-height:550px;max-width: 1200px;">
                        <div class="simulador__content">
                            <div class="content__coin" style="display:flex; justify-content: end;">
                                <div style="display: flex;justify-content: center;width: 50%;">
                                    <img src="/images/simulador/coin_simulador.png" alt="coin simulador">
                                </div>
                            </div>
                            <div class="content__titulo">
                                <span v-html="titulo"></span>
                            </div>
                            <div class="content__body">
                                <div class="body__slider">
                                     <!-- Cash -->
                                     <div class="slider__cash">
                                        <div class="cash__title">
                                            <span class="title__txt">{{sliderTitulo1}}</span>
                                            <span class="title__cash">{{sliderCash | currency('S/') }}</span>
                                        </div>
                                        <v-slider
                                            v-model="sliderCash"
                                            :min="10000"
                                            :max="1000000"
                                        ></v-slider>
                                        <div class="cash__label">
                                            <span>S/10.000</span>
                                            <span>+S/1.000.000</span>
                                        </div>
                                    </div>
                                    <!-- Day -->
                                    <div class="slider__day">
                                         <div class="day__title">
                                            <span>{{sliderTitulo2}}</span>
                                        </div>
                                        <v-slider
                                            v-model="sliderDay"
                                            :tick-labels="ticksLabels"
                                            :max="120"
                                            :min="30"
                                            step="30"
                                            ticks="always"
                                            tick-size="2"
                                        ></v-slider>
                                        <div class="day__label">
                                            <span class="label__start">30 Días</span>
                                            <span class="label__end">120 Días</span>
                                        </div>
                                    </div>
                                    <div class="slider__paragraph">
                                        <p>
                                            *Las cifras que se muestran en esta calculadora son para fines ilustrativos y no
                                            constituyen una oferta
                                        </p>
                                    </div>
                                </div>
                                <div class="body__resumen">
                                    <div class="resumen__title">
                                        <span>Resumen del financiamiento</span>
                                    </div>
                                    <ul class="resumen__detail">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <li v-on="on">
                                                    <span class="detail__txt">Monto neto de la factura</span>
                                                    <span class="detail__cash">{{sliderCash | currency('S/') }}</span>
                                                </li>
                                            </template>
                                            <span>
                                                Es el importe que resulta al restar las detracciones, retenciones,<br>
                                                notas de crédito u otras deducciones al monto bruto de la factura.
                                            </span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <li v-on="on">
                                                    <span class="detail__txt">Monto a financiar</span>
                                                    <span class="detail__cash">{{sliderCashFinanciar | currency('S/')}}</span>
                                                </li>
                                            </template>
                                            <span>
                                                Es el importe que resulta al restar el porcentaje que retiene RedCapital<br>
                                                como garantía para cubrir una eventual mora o diferencia en el pago.
                                            </span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <li v-on="on">
                                                    <span class="detail__txt">Intereses y costos</span>
                                                    <span class="detail__cash">-{{sliderInteresesCostos | currency('S/')}}</span>
                                                </li>
                                            </template>
                                            <span>
                                                Los intereses se calculan tomando en cuenta el monto a financiar y el plazo exacto de la operación.<br>
                                                Si bien el cálculo del mismo se hace en función a los días del financiamiento, se presenta como una<br>
                                                tasa efectiva anual (TEA) para que sea fácilmente comparable. Adicionalmente, se cobran gastos<br>
                                                administrativos por cada operación.
                                            </span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <li v-on="on">
                                                    <span class="detail__txtfinal">Monto a desembolsar</span>
                                                    <span class="detail__cashfinal">{{sliderRetorno | currency('S/')}}</span>
                                                </li>
                                            </template>
                                            <span>
                                                Es el importe exacto que recibirá la Pyme como adelanto por las facturas<br>
                                                cedidas, luego de descontar los conceptos anteriormente detallados.
                                            </span>
                                        </v-tooltip>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			titulo : String,
            sliderTitulo1: String,
            sliderTitulo2: String,
		},
        data() {
            return {
                sliderDay: 30,
                sliderCash: 10000,
                sliderCashFinanciar: 0,
                sliderRetencionRC: 0,
                sliderInteresesCostos: 0,
                sliderCostoOperacional: 10,
                sliderTasaAnual: 0,
                sliderRetorno: 0,
                tasaAnual: 12,
                diasAnual: 360,
                ticksLabels: [
                    '30',
                    '60',
                    '90',
                    '120'
                    ]
            }
        },
        beforeMount() {
            // @ts-ignore
            this.calcSimulador();
        },
        methods: {
            calcSimulador(){
                this.resetTotal();
                // Calculo Tasa
                this.$data.sliderTasaAnual = (((this.$data.tasaAnual * this.$data.sliderDay) / this.$data.diasAnual) / 100);
                // Retencion RC
                this.$data.sliderRetencionRC = this.$data.sliderCash * this.$data.sliderTasaAnual;
                // Monto a financiar
                this.$data.sliderCashFinanciar = Math.round(this.$data.sliderCash - this.$data.sliderRetencionRC);
                // Intereses y costos
                this.$data.sliderInteresesCostos = Math.round((this.$data.sliderCashFinanciar - this.$data.sliderCostoOperacional) * this.$data.sliderTasaAnual);
                // Retorno Esperado
                this.$data.sliderRetorno = Math.round(this.$data.sliderCashFinanciar - this.$data.sliderInteresesCostos);
            },
            resetTotal(){
                this.$data.sliderTasaAnual = 0;
                this.$data.sliderRetencionRC = 0;
                this.$data.sliderCashFinanciar = 0;
                this.$data.sliderInteresesCostos = 0;
                this.$data.sliderRetorno = 0;
            }
        },
        watch: {
            sliderDay(data) {
                // @ts-ignore
                this.calcSimulador();
            },
            sliderCash(data) {
                // @ts-ignore
                this.calcSimulador();
            }
        }
	})
	export default class Factoring extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-simulador{
        min-height: 800px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .simulador__content{
            display: flex;
            flex-flow: column;
            padding: 3rem 2rem;
            .content__coin{
                display: flex;
               
                    img{
                        position: absolute;
                        width: 200px;
                        top: -60px;
                    }
            }
            .content__titulo{
                font-size: 1.5rem;
                span{
                    color: $rc-prim-g2;
                }
                margin-bottom: 2rem;
            }
            .content__body{
                display: flex;
                flex-flow: wrap;
                .body__slider{
                    width: 50%;
                    order: 1;
                    .slider__cash{
                        max-width: 500px;
                        margin-bottom: 2rem;
                        .cash__title{
                            display: flex;
                            flex-flow: column;
                            .title__txt{
                                font-size: 1.2rem;
                            }
                            .title__cash{
                                font-size: 1.2rem;
                                font-weight: 600;
                                color: $rc-prim-n1;
                            }
                        }

                        .cash__label{
                            display: flex;
                            justify-content: space-between;
                            span{
                                font-size: 14px;
                                color: #DEE2E6;
                            }
                        }
                    }
                    .slider__day{
                        max-width: 500px;
                        .day__title{
                            display: flex;
                            flex-flow: column;
                            span{
                                font-size: 1.2rem;
                            }
                        }
                        .day__label{
                            display: flex;
                            justify-content: space-between;
                            span{
                                font-size: 14px;
                                color: #DEE2E6;
                            }
                        }
                    }
                    .slider__paragraph{
                        p{
                            padding-top: 2rem !important;
                        }
                    }
                }
                .body__resumen{
                    width: 50%;
                    order:2;
                    margin-top: 60px;
                    .resumen__title{
                        display: flex;
                        justify-content: center;
                        span{
                             font-size: 1.2rem;
                        }
                        margin-bottom: 2rem;
                    }
                    .resumen__detail{
                        list-style: none;
                        li{
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 1rem;
                            .detail__txt{
                                font-size: 1rem;
                                font-weight: 600;
                            }
                            .detail__cash{
                                font-size: 1.1rem;
                                font-weight: 600;
                            }
                            .detail__txtfinal{
                                font-size: 1.2rem;
                                font-weight: 600;
                            }
                            .detail__cashfinal{
                                font-size: 1.2rem;
                                color: #FFF;
                                background: $rc-prim-n1;
                                border-radius: 8px;
                                padding: 0.6rem 1rem;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
                
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
                
            }@else if $breakpoint == "small-to-medium"{
                .simulador__content{
                    .content__coin{
                        justify-content: center !important;
                    }
                    .content__titulo{
                        margin-top: 8rem !important;
                    }
                    .content__body{
                        .body__slider{
                            width: 100% !important;
                            .slider__paragraph{
                                p{
                                    padding-top: 1rem !important;
                                }
                            }
                        }
                        .body__resumen{
                            width: 100% !important;
                            .resumen__detail{
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }@else if $breakpoint == "small"{
                .simulador__content{
                    .content__coin{
                        justify-content: center !important;
                    }
                    .content__titulo{
                        margin-top: 8rem !important;
                    }
                    .content__body{
                        .body__slider{
                            width: 100% !important;
                            .slider__paragraph{
                                p{
                                    padding-top: 1rem !important;
                                }
                            }
                        }
                        .body__resumen{
                            width: 100% !important;
                            .resumen__detail{
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
		}
	}
</style>
